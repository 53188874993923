<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-12-20 20:59:25
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-12-21 12:38:21
-->
<template>
  <el-dialog
    :title="title"
    v-model="visible"
    width="700"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    @closed="onClosed"
  >
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140px"
      :inline="false"
    >
      <el-form-item label="项目">
        {{ form.estateName }}
        <choose-estate @change="onEstateChange"></choose-estate>
      </el-form-item>
      <el-form-item label="客服">
        <span v-if="form.id">{{ form.name }}({{ form.mobile }})</span>
        <choose-consumer
          v-else
          single
          :companyId="1"
          @success="onChooseConsumerChange"
        ></choose-consumer>
      </el-form-item>
      <el-form-item label="上班时间">
        <el-col :span="20">
          <el-input v-model="form.workingTime" placeholder=""></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="隐藏客户号码">
        <el-radio-group v-model="form.isHideTel">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="处理报备">
        <el-radio-group v-model="form.isOperation">
          <el-radio :label="0">处理</el-radio>
          <el-radio :label="1">禁止</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="visible = false" :disabled="saving">取消</el-button>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import ChooseConsumer from "@/views/component/choose_consumer.vue";
import ChooseEstate from "@/views/component/choose_estate.vue";
export default {
  components: {
    ChooseConsumer,
    ChooseEstate,
  },
  data() {
    return {
      visible: false,
      saving: false,
      title: "",
      form: {},
      rules: {},
    };
  },
  props: {
    item: Object,
  },
  emits: ["closed", "success"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = Object.assign(
            {
              isHideTel: true,
              isOperation: 1,
            },
            this.item
          );
          this.visible = true;
          if (this.item.id) {
            this.title = "修改";
          } else {
            this.title = "新增";
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    onClosed() {
      this.$emit("closed");
    },

    /**
     * 人员选择回调
     */
    onChooseConsumerChange(list) {
      if (list && list.length > 0) {
        this.form.consumerId = list[0].id;
      }
    },

    /**
     * 楼盘选择更改
     * @param {*} id
     */
    onEstateChange(id) {
      this.form.estateId = id;
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("admin/v1/assistant/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.$emit("success");
                this.visible = false;
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },
  },
};
</script>

<style scoped></style>
