<!--
 * @Description: 选择人员、单选、多选
 * @Author: 琢磨先生
 * @Date: 2023-12-16 09:06:49
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-04-20 08:47:38
-->
<template>
  <div @click="onOpen" style="line-height: 0">
    <slot>
      <el-button type="primary" link :icon="`${icon ? icon : 'Plus'}`">
        {{ text ? text : "选择人员" }}
      </el-button>
    </slot>
  </div>
  <div class="selection_wrp" v-if="isShow" style="width: 100%">
    <el-tag
      style="margin: 0 10px 10px 0"
      type="info"
      v-for="item in selection_list"
      :key="item.id"
      >{{ item.name }} {{ item.mobile }}</el-tag
    >
  </div>

  <el-dialog
    :title="title"
    v-model="visibleDialog"
    top="10vh"
    width="700px"
    draggable
    :destroy-on-close="true"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div style="height: 35vh">
      <el-table
        ref="empTable"
        :data="member_list"
        row-key="id"
        @row-click="rowClick"
        style="height: 100%"
      >
        <el-table-column label="姓名" prop="name">
          <template #header>
            <el-input
              v-model="q"
              placeholder="手机号、姓名搜索"
              @input="inputChange"
            ></el-input>
          </template>
          <template #default="scope">
            <div class="flex-row item-center">
              <el-avatar size="small" :src="scope.row.avatar_url" />
              <span style="margin-left: 10px"
                >{{ scope.row.name }} {{ scope.row.mobile }}</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-top: 20px">
      <el-alert type="info" :closable="false" title="已选择用户">
        <el-tag
          class="choose_member"
          v-for="(item, i) in selection_nodes"
          :key="item.id"
          closable
          @close="onRemove(i)"
        >
          <div class="flex-row item-center">
            <!-- <el-avatar :size="25" :src="item.avatar_url"></el-avatar> -->
            <span style="margin-left: 5px"
              >{{ item.name }} {{ item.mobile }}</span
            >
          </div>
        </el-tag>
      </el-alert>
    </div>
    <template #footer>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visibleDialog: false,
      loading: false,
      q: "",
      member_list: [],
      //选中的人员
      selection_nodes: [],
      selection_list: [], //
    };
  },
  props: {
    single: Boolean, //单选
    text: {
      type: String,
      default: "选择用户",
    },
    list: Array,
    title: {
      type: String,
      default: "点击选择人员",
    },
    icon: String,
    isMkt: Boolean,
    companyId: Number,
    //显示选择的用户
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["success"],
  created() {
    this.loadData();
  },
  watch: {
    list: {
      handler() {
        this.selection_list = [];
        if (this.list) {
          this.list.forEach((item) => {
            this.selection_list.push({
              id: item.id,
              name: item.name,
              mobile: item.mobile,
            });
          });
          // this.$emit("success", this.selection_list);
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    onOpen() {
      this.selection_nodes = [];
      this.visibleDialog = true;
      this.$nextTick(() => {
        if (this.selection_list && this.selection_list.length > 0) {
          if (this.single) {
            this.selection_nodes = [Object.assign({}, this.selection_list[0])];
          } else {
            this.selection_nodes = this.selection_list.map((x) =>
              Object.assign({}, x)
            );
          }
        }
      });
    },

    /**
     * 输入查询
     */
    inputChange() {
      this.loadData();
    },

    /**
     * 点击选择
     * @param {*} node
     */
    rowClick(node) {
      if (!this.selection_nodes.find((o) => o.id == node.id)) {
        if (this.single) {
          this.selection_nodes = [
            {
              id: node.id,
              name: node.name,
              mobile: node.mobile,
            },
          ];
        } else {
          this.selection_nodes.push({
            id: node.id,
            name: node.name,
            mobile: node.mobile,
          });
        }
      }
    },

    /**
     * 移除选择
     */
    onRemove(i) {
      this.selection_nodes.splice(i, 1);
    },

    /**
     * 加载人员
     */
    loadData() {
      this.$http
        .get(
          `admin/v1/consumer/get_select?q=${this.q}&isMkt=${
            this.isMkt ? "true" : ""
          }&companyId=${this.companyId ? this.companyId : ""}`
        )
        .then((res) => {
          if (res.code == 0) {
            this.member_list = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 确定选择
     */
    onConfirm() {
      this.selection_list = this.selection_nodes.map((x) =>
        Object.assign({}, x)
      );
      this.$emit("success", this.selection_list);
      this.visibleDialog = false;
    },
  },
};
</script>

<style scoped>
.selection_wrp {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.choose_member {
  margin: 0 10px 10px 0;
  height: auto;
  padding: 10px;
}
.el-table td.el-table__cell div {
  cursor: pointer;
}
</style>
